import $ from "jquery";

var ts;
var lastY;
$(document).ready(function () {
    if ($('[data-scroll="smooth"]').length > 2) {
        initSection();
        let isEnable = false, direction = 0;

        if ($(window).width() > 450) {
            console.log('demo scroll 450');
            $(window).bind('mousewheel DOMMouseScroll', function (event) {
                runDesktop(event);
            });
        } else {
            $(document).bind('touchstart', function (e) {
                ts = e.originalEvent.touches[0].clientY;
            });

            $(document).bind('touchend', function (e) {
                runMobile(e);
            });
        }

        function runMobile(event) {
            var te = event.originalEvent.changedTouches[0].clientY;
            if (ts > te + 5) {
                direction = 2;
                moveNext();
            } else if (ts < te - 5) {
                direction = 1;
                moveNext();
            }
        }


        function runDesktop(event) {
            if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
                direction = 1;
                moveNext();
            } else {
                direction = 2;
                moveNext();
            }
        }

        function initSection() {
            setActive($('[data-scroll="smooth"]').first());
        }

        function setActive(el) {
            el.addClass('active');
        }

        function moveNext() {
            if (isEnable || direction === 0) return;

            let current = findCurrentActiveElement();
            let nextElement = findNextElement(current);

            if (current.length > 0 && nextElement.length > 0) {
                isEnable = true;
                changeImage(nextElement);
                animateMove(nextElement);
                current.removeClass('active');
                nextElement.addClass('active');
            }
        }

        function changeImage(nextElement) {
            let image = $('.float-litera'), time = 500;

            image.fadeOut(200, function () {
                $(this).attr('src', nextElement.data('litera-path')).fadeIn(500);
            });
        }

        function findCurrentActiveElement() {
            return $('.active[data-scroll="smooth"]');
        }

        function findNextElement(currentElement) {
            if (direction === 1) return $(currentElement.prevAll('[data-scroll="smooth"]')[0]);
            if (direction === 2) return $(currentElement.nextAll('[data-scroll="smooth"]')[0]);
        }


        function animateMove(el) {
            if ($(el).length === 0) return false;

            $([document.documentElement, document.body]).animate({
                scrollTop: $(el).offset().top
            }, 500, function () {
                isEnable = false;
            });
        }

        function lockScroll() {
            var $html = $('html');
            var $body = $('body');
            var initWidth = $body.outerWidth();
            var initHeight = $body.outerHeight();

            var scrollPosition = [
                self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
                self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            ];
            $html.data('scroll-position', scrollPosition);
            $html.data('previous-overflow', $html.css('overflow'));
            $html.css('overflow', 'hidden');
            window.scrollTo(scrollPosition[0], scrollPosition[1]);

            var marginR = $body.outerWidth() - initWidth;
            var marginB = $body.outerHeight() - initHeight;
            $body.css({'margin-right': marginR, 'margin-bottom': marginB});
        }

        lockScroll();
    }
});