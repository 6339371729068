import $ from 'jquery';

window.$ = window.jQuery = $;

import './SmoothScroolJquery';

$(document).ready(function () {
    $('#callbackForm').submit(function (e) {
        e.preventDefault();
        let form = $(this);

        $.ajax({
            url: form.attr('url'),
            data: form.serialize(),
            method: form.attr('method'),
            success: function (data) {
                data = JSON.parse(data);
                console.log(data);

                if (data.success === false && data.message.error) {
                    for (var i in data.message.error) {
                        $('[name="' + i + '"]').addClass('error');
                        $('#form-error-' + i).text(data.message.error[i]);
                    }
                }

                if (data.success == true) {
                    // form.reset();
                    $('#callbackModal').modal('hide');
                    $("#siteInfoToast").toast('show');
                }
            }
        });
    });

    $('.stairs-group').click(function () {
        $('.stairs').toggleClass('active');
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#button-up').fadeIn();
        } else {
            $('#button-up').fadeOut();
        }
    });

    $('#button-up').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500, function () {
            $('[data-scroll="smooth"]').each(function (i, el) {
                $(el).removeClass('active');
            }).first().addClass('active');

            let image = $('.float-litera'), time = 500;

            image.fadeOut(200, function () {
                $(this).attr('src', $('[data-scroll="smooth"]').first().data('litera-path')).fadeIn(500);
            });
        });
        return false;
    });

});

var load_more = false;

$(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 100) {
        $('.header').addClass('header-sticky');
    } else {
        $('.header').removeClass('header-sticky');
    }

    if ($("#ajax_next_page").length && !load_more) {
        var url = $("#ajax_next_page").attr("href");
        var offset_button = $("#ajax_next_page").offset();
        if ($(this).scrollTop() >= offset_button.top - $(window).height()) {
            load_more = true;
            $.ajax({
                url: url,
                type: "POST",
                data: {IS_AJAX: 'Y'},
                success: function (data) {
                    $("#ajax_pager_result").append(data);
                    $("#ajax_next_page").remove();
                    load_more = false;
                }
            });
        }
    }
});