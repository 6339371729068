class SetupingForm {
    constructor() {
        this.element = document.querySelector('.js-setuping-form');

        if (this.element) {
            this.initEvents();
        }
    }

    initEvents() {
        this.element.onsubmit = this.handleSubmitForm.bind(this);

    }

    handleSubmitForm(event) {
        event.preventDefault();
        let form = event.target;

        if (!this.validate(form)) return false;

        fetch(form.getAttribute('action'), {
            body: new FormData(form),
            method: form.getAttribute('method'),
        }).then(response => response.json()).then(data => {
            let form_elements = document.querySelectorAll('.setuping-form__label');
            form_elements.forEach(el => {
                el.classList.remove('has-error');
                let error = el.querySelector('.setuping-form__error');
                if (error) error.innerHTML = "";
            });

            if (data.success == true) {
                form.reset();
                $('#notifySiteModal').modal('show').find('.js-insert-notify-text').text('Ваша заявка успешно отправлена. Мы свяжемся с вами в ближайшее время!');
            }


            if (data.success == false) {
                Object.keys(data.message.error).map(key => {
                    let form_element = document.querySelector('#form-element-' + key);
                    if (form_element) {
                        let error_div = form_element.querySelector('.setuping-form__error');
                        if (error_div) {
                            form_element.classList.add('has-error');
                            error_div.innerHTML = data.message.error[key];
                        }
                    }
                });
            }
        });

    }

    validate(form) {
        let result = true;
        let labels = form.querySelectorAll('.setuping-form__label');
        if (labels) {
            labels.forEach(label => {
                let input = label.querySelector('input');
                if (input) {
                    result = this.validateInput(label);
                }
            });
        }

        return result
    }

    validateInput(label) {
        let input = label.querySelector('input');
        let error = label.querySelector('.setuping-form__error');

        if (input.value.length === 0) {
            error.innerHTML = 'Нужно заполнить';
            label.classList.add('has-error');
            return false;
        }

        return true;
    }
}


export default SetupingForm;