import 'bootstrap';
import 'lightbox2';
import $ from 'jquery';
// window.$ = window.jQuery = $;


import './init/sliders';
import './init/maskedinput';
import './include/jquery';

import SetupingForm from "./include/block/SetupingForm";

new SetupingForm();