import Swiper from 'swiper';

let indexPageSliderContainer = new Swiper('.index-page-slider-container', {
    loop: true,
    pagination: {
        el: '.index-page-slider-pagination',
    }
});

let indexPageReviewsContainer = new Swiper('.index-reviews-container', {
    loop: true,
    pagination: {
        el: '.index-reviews-pagination',
    }
});

let indexPageBlogContainer = new Swiper('.index-blog-container', {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 20,
    pagination: {
        el: '.section-index-blog-pagination',
        dynamicBullets: true,
    },
    breakpoints: {
        480: { // when window width is >= 480px
            slidesPerView: 3,
        },
    }
});

let pageSliderContainer = new Swiper('.page-slider-container', {
    slidesPerView: 1,
    loop: true,
    pagination: {
        el: '.page-slider-pagination',
    },
});

let blogDetailContainer = new Swiper('.blog-detail-slider-container', {
    slidesPerView: 1,
    loop: true,
    pagination: {
        el: '.blog-detail-slider-pagination',
    },
});

let aboutContainer = new Swiper('.about-slider-container', {
    slidesPerView: 1,
    loop: true,
    pagination: {
        el: '.about-slider-pagination',
    },
});

let teamContainer = new Swiper('.team-container', {
    slidesPerView: 1,
    // loop: true,
    pagination: {
        el: '.team-pagination',
    },
});

var swiper55 = new Swiper(".Swiper_seo", {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: ".ew_seo_pagination",
        clickable: true,
    },
    autoplay: {
        delay: 3000,
    },
    navigation: {
        nextEl: ".button_new_seo-next",
        prevEl: ".button_new_seo-prev",
    },
});